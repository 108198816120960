<template>
	<div>
		<div slot="toolbar">
			<mu-icon-button 
				@click="$router.go(-1)" 
				icon="arrow_back" 
			/>
		</div>

		<div class="phi-page-contents map" v-if="app.user && target" >
			<!--<div class="phi-card target">
				<div class="phi-media">
					<div class="phi-media-body">
						<span v-if="enabled == true || errorTracking == true"> 
							<span v-if="errorTracking == true"> {{ messageError }} </span>
							<span v-else> {{ $t("tracking") }} {{ target.name }}</span>	
						</span>
						<span v-else> {{ $t("startRoute") }}: {{ target.name }} </span>
					</div>
					<div class="phi-media-right" v-if="gpsenable">
						<mu-switch :value="enabled" @change="enabled = !enabled"/>
					</div>
				</div>
			</div>-->
			<phi-tracker 
				:target="target.id" 
				:every="every" 
				:enabled="enabled" 
				:targetName="target"
				:speakRoute="volume"
				@update="update" 
				@trackMessage="trackMessage" 
				@gpsresult="gpsresult"
			>
			</phi-tracker>
			<transition name="fade">
				<toggle 
					v-if="gpsenable && !enabled"
					:message="message" 
					:target="target.name"
					@togglecheck="toggleCheck"				>	
				</toggle>
			</transition>
			<transition name="fade">
				<div id="options" v-if="enabled">
					<div class="option-volume">
						<img :src="speak_url" @click="changeVolume">
					</div>
					<div class="option-stop">
						<img :src="stop_url" @click="toggleCheck(!enabled)">
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import PhiTracker from '../../components/Phi/Tracker.vue';
import Toggle from '../../components/Phi/Toggle/Toggle.vue';
import app from '../../store/app.js'

export default {
	name: "geo-tracker",
	components: {PhiTracker, Toggle},

	beforeRouteEnter(to, from, next) {
		app.api
			.get(`/geo/targets/${to.params.targetId}`)
			.then(target => next(vm => vm.target = target));
	},

	data() {
		return {
			app,
			target: null,
			every: 5000,
			enabled: false,
			log: [],
			open: false,
			errorTracking: false,
			messageError: null,
			gpsenable: false,
			message: 'Desliza para iniciar recorrido',
			volume: true,
			speak_url: "https://s3.amazonaws.com/phidias-felipe/svg/volumeOn.png",
			stop_url: "https://s3.amazonaws.com/phidias-felipe/svg/stop.png"
		}
	},
	methods: {
		changeVolume() {
			this.volume = !this.volume;
			this.volume ? this.speak_url = "https://s3.amazonaws.com/phidias-felipe/svg/volumeOn.png" : this.speak_url = "https://s3.amazonaws.com/phidias-felipe/svg/volumeOff.png";	
		},
		update(position) {
			this.errorTracking = false;
			this.log.push(position);
		},
		trackMessage(error) {
			this.errorTracking = true;
			this.messageError  = error;
		},
		gpsresult(value) {
			this.gpsenable = value;
		},
		toggleCheck(value) {
			console.log("Valor desde el toggle", value)
			this.enabled = value;

			/*if (this.enabled || this.errorTracking) {
				if (this.errorTracking && !this.gpsenable) {
					this.message = this.messageError;
				}
				else if (this.gpsenable && this.enabled) {
					this.message = this.$t("tracking") +" "+ this.target.name
				}
			}
			else {
				this.message = this.$t("startRoute")
			}*/
		}
	}
}
</script>

<style lang="css" scoped>
.target {
	padding: 12px;

	h1 {
		margin-bottom: 0px;
	}

	img {
		max-width: 200px;
	}

	.phi-media {
		padding: 0px;
	}
}

.phi-page-contents {
	h2 {
		font-weight: 1em;
		text-transform: uppercase;
		color: #666;
		margin-bottom: 0.5em;
		font-size: 0.8em;
	}
}
.mu-appbar {
    background-color: inherit;
    color: inherit;
}

#toggle {
	position: absolute;
	bottom: 0px;
	margin: auto;
	text-align: center;
	width: 100%;
}

#options {
	bottom: 28px;
	position: absolute;
	right: 30px;
	height: auto;
}

.option-stop {
	background-color: white;
	border-radius: 50%;
	box-shadow: 0 0 5px rgba(107, 102, 102, .6);
	cursor: pointer;
	height: 45px;
	margin: auto;
	margin-bottom: 8px;
	padding-top: 14px;
	text-align: center;
	width: 45px;
}

.option-volume {
	background-color: white;
	border-radius: 50%;
	box-shadow: 0 0 5px rgba(107, 102, 102, .6);
	cursor: pointer;
	height: 45px;
	margin: auto;
	margin-bottom: 8px;
	padding-top: 14px;
	text-align: center;
	width: 45px;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
